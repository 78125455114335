import Columns from "../../common/layout/Columns";
import Column from "../../common/layout/Column";
import Select from "react-select";
import {useEffect, useRef, useState} from "react";
import Table from "../../common/elements/Table";
import "../css/insights.css"
import InfoCard from "../../common/elements/InfoCard";
import {buildDataState, buildLoadingState} from "../../../utils/StateUtils";
import StateHandler from "../../common/elements/StateHandler";
import SmallLoadingSpinner from "../../common/elements/SmallLoadingSpinner";

export const AttributeValuesTable = (props) => {

    const [attributeSelected, setAttributeSelected] = useState(undefined);

    const attributeSelectRef = useRef()
    const optionalSelectRef = useRef()

    const [optionalSelected, setOptionalSelected] = useState(undefined);
    const [attributeValues, setAttributeValues] = useState(buildLoadingState());

    useEffect(() => {
        if (attributeSelected) {
            setAttributeValues(buildLoadingState())
            props.tableDataCallback(attributeSelected, optionalSelected)
                .then((data) => {
                    if (!data || !data.results) {
                        return;
                    }
                    setAttributeValues(buildDataState(data.results))
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [attributeSelected, optionalSelected])

    useEffect(() => {
        setAttributeValues(buildLoadingState())
        setAttributeSelected(undefined)
        setOptionalSelected(undefined)
        if (attributeSelectRef.current) {
            attributeSelectRef.current.clearValue()
        }
        if (optionalSelectRef.current) {
            optionalSelectRef.current.clearValue()
        }
    }, [props.experimentKey, props.timeRange])

    function getDropdownLoader() {
        return <div className="button mt-4 mb-4 is-fullwidth">
            <span className="mt-4 mb-4 mr-4">Loading attributes..</span>
            <SmallLoadingSpinner/>
        </div>
    }

    return (
        <div className="centered-column">
            <span className="matching-title">Attribute Values</span>
            <StateHandler showLoader={props.attributes.loading} showError={props.attributes.error}
                          loadingScreen={getDropdownLoader()}>
                <Columns className="is-multiline">
                    <Column className="is-one-third ">
                        <Select
                            ref={attributeSelectRef}
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            isSearchable={true}
                            name="test"
                            options={props.attributes.data?.map((value, index) => {
                                return {
                                    value: index,
                                    label: value.aggregationIdentifier
                                }
                            })}
                            onChange={(selected) => {
                                if (selected === null) {
                                    setAttributeSelected(undefined);
                                    return;
                                }
                                setAttributeSelected(selected.label);
                            }}
                            placeholder={"Attribute"}
                        />
                    </Column>
                    <Column className="is-two-thirds">
                        <Select
                            ref={optionalSelectRef}
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            isSearchable={true}
                            name="test"
                            options={props.optionalSelectionOptions}
                            onChange={(selected) => {
                                if (selected === null) {
                                    setOptionalSelected(undefined);
                                    return;
                                }
                                setOptionalSelected(selected.label);
                            }}
                            placeholder={props.optionalSelection}
                        />
                    </Column>
                </Columns>
                {attributeSelected === undefined ?
                    <InfoCard title={"You need to select an attribute."}/>
                    :
                    <StateHandler showLoader={attributeValues.loading} showError={attributeValues.error}>
                        <Table headers={{
                            className: "has-text-white",
                            values: [{value: attributeSelected}, {
                                value: "# of logged decisions"
                            }]
                        }}
                               rows={attributeValues.data?.map((value, index) => {
                                   //a row's data
                                   return {
                                       id: index,
                                       values: [{value: value['aggregationIdentifier']}, {value: value['counter']}]
                                   }
                               })}
                               pageSize={6}
                        />
                    </StateHandler>
                }
            </StateHandler>
        </div>
    );
}
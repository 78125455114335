import { useState } from "react";
import Icon from "./common/elements/Icon";

import {faHouse, faBinoculars, faGavel} from "@fortawesome/free-solid-svg-icons";

/**
 * A global navbar for the site.
 *
 */
export const GlobalNavBar = (props) => {
    const [activeClass, setActiveClass] = useState('');

    function toggleNavbarClass() {
        if (activeClass !== "") {
            setActiveClass('')
        } else {
            setActiveClass("is-active")
        }
    }

    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <a className="navbar-item" href="/">
                    <strong>Experimentation</strong>
                </a>
                <a role="button" className={`navbar-burger ${activeClass}`}
                   onClick={toggleNavbarClass}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </a>
            </div>
            <div className={`navbar-menu ${activeClass}`}>
                <div className="navbar-start">
                    <a className="navbar-item" href="/">
                        <span className="icon-text">
                            <Icon icon={faHouse}/>
                            <span>Home</span>
                        </span>
                    </a>
                    <a className="navbar-item" href="/real-time-insights">
                        <span className="icon-text">
                            <Icon icon={faBinoculars}/>
                            <span>Real-time Insights</span>
                        </span>
                    </a>
                </div>
                <div className="navbar-end"/>
            </div>
        </nav>
    );
}

export default GlobalNavBar;
import './App.css';
import {MsalProvider} from '@azure/msal-react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {GlobalNavBar} from "./components/GlobalNavBar";
import {HomeComponent} from "./components/home/HomeComponent";
import {RealTimeInsightsComponent} from "./components/insights/page/RealTimeInsightsComponent";

//TODO /thor/real-time-insights or /real-time-insights or ?????
const App = ({instance}) => {
    return (
        <BrowserRouter>
            <GlobalNavBar/>
            <Routes>
                <Route exact={true} path="/" element={
                    <MsalProvider instance={instance}>
                        <HomeComponent/>
                    </MsalProvider>
                }></Route>

                <Route exact={true} path="/real-time-insights" element={
                    <MsalProvider instance={instance}>
                        <RealTimeInsightsComponent>
                        </RealTimeInsightsComponent>
                    </MsalProvider>
                }></Route>
            </Routes>
        </BrowserRouter>
    );
};

export default App;

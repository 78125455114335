/**
 * A Button.
 *
 * -- Props --
 * | Name         | Type      | Required  | Default Value | Description
 * |--------------|-----------|-----------|---------------|---------------
 * | id           | string    | false     | null          | An id to apply to this component.
 * | className    | string    | false     | ""            | Additional classes to apply to this component.
 * | disabled     | boolean   | false     | false         | Whether the button is disabled or not.
 * | href         | string    | false     | null          | An href for on-click events.
 * | target       | string    | false     | _blank        | The href target.
 * | onClick      | function  | false     | null          | An onClick handler.
 * | onMouseLeave | function  | false     | null          | An onMouseLeave function.
 * | tooltip      | string    | false     | null          | A tooltip to display.
 */
export const Button = (props) => {
    let component = (
        <a id={props.id || null}
           className={`button${props.className ? " " + props.className : ""}`}
           href={props.href || null}
           target={props.href ? (props.target || "_blank") : null}
           onClick={props.onClick}
           onMouseLeave={props.onMouseLeave}
           data-tooltip={props.tooltip}
        >
            {props.children}
        </a>
    );

    if (props.disabled) {
        component = (
            <button id={props.id || null}
                    className={`button${props.className ? " " + props.className : ""}`}
                    disabled={true}
            >
                {props.children}
            </button>
        );
    }

    return component;
}

export default Button;

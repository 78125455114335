import {AuthenticatedTemplate} from '@azure/msal-react';
import {Login} from '../../../Login';
import Hero from "../../common/layout/Hero";
import HeroBody from "../../common/layout/HeroBody";
import Container from "../../common/layout/Container";
import Columns from "../../common/layout/Columns";
import Column from "../../common/layout/Column";
import Select from "react-select";
import {useEffect, useState} from "react";
import "../css/insights.css"
import InfoCard from "../../common/elements/InfoCard";
import {ExperimentMetadata} from "../components/ExperimentMetadata";
import {ExperimentStats} from "../components/ExperimentStats";
import useFetchWithMsal from "../../../useFetchWithMsal";
import {thor_api_urls} from "../../common/Constants";
import SmallLoadingSpinner from "../../common/elements/SmallLoadingSpinner";
import {buildDataState, buildErrorState, buildLoadingState} from "../../../utils/StateUtils";
import StateHandler from "../../common/elements/StateHandler";
import {useSearchParams} from "react-router-dom";

export const RealTimeInsightsComponent = () => {
    const {execute} = useFetchWithMsal();

    const [searchParams, setSearchParams] = useSearchParams();

    const [experimentSelected, setExperimentSelected] = useState(undefined);

    const [experimentsMetadata, setExperimentsMetadata] = useState(buildLoadingState(new Map()))

    const [experimentLabels, setExperimentLabels] = useState([])

    function buildLoadingExperimentsScreen() {
        return <div className="button mt-4 mb-4 is-fullwidth">
            <span className="mt-4 mb-4 mr-4">Loading experiments..</span>
            <SmallLoadingSpinner/>
        </div>
    }

    const fetchExperiments = () => {
        execute('GET', thor_api_urls.listAllExperiments)
            .then((data) => {
                if (!data || !data.experiments) {
                    return;
                }
                let experiments = new Map()
                for (const experiment of data.experiments) {
                    experiments.set(experiment.key, experiment)
                }
                setExperimentsMetadata(buildDataState(experiments))
                const experimentKeyInUrl = searchParams.get("experimentKey");
                if (experimentKeyInUrl && experiments.has(experimentKeyInUrl)) {
                    setExperimentSelected(experiments.get(experimentKeyInUrl))
                }
            })
            .catch((error) => {
                setTimeout(() => {
                    if(experimentsMetadata.loading) {
                        setExperimentsMetadata(buildErrorState(error))
                    }
                }, 1000)
                console.log(error);
            });
    }

    useEffect(() => {
        fetchExperiments()
    }, [execute])

    useEffect(() => {
        if (experimentSelected === null) {
            addParamToUrl('experimentKey', '');
        } else if (experimentSelected) {
            addParamToUrl('experimentKey', experimentSelected.key);
        }
    }, [experimentSelected])

    useEffect(() => {
        mapExperimentMetadataToLabels()
    }, [experimentsMetadata])

    function addParamToUrl(key, value) {
        const url = new URL(window.location.href);
        url.searchParams.set(key, value);
        window.history.replaceState(null, null, url); //prevents page reload
    }

    const mapExperimentMetadataToLabels = () => {
        let experimentsArray = new Array(experimentsMetadata.data.size)
        for (let [key, value] of experimentsMetadata.data) {
            experimentsArray.push({value: experimentsArray.length, label: value["key"]})
        }
        setExperimentLabels(experimentsArray.sort((a, b) => a.label.localeCompare(b.label)))
    }

    return (
        <>
            <Login/>
            <AuthenticatedTemplate>
                <Container>
                    <Hero>
                        <HeroBody>
                            <p className="title is-1">Real-Time Insights</p>
                        </HeroBody>
                    </Hero>

                    <StateHandler showLoader={experimentsMetadata.loading} showError={experimentsMetadata.error}
                                  loadingScreen={buildLoadingExperimentsScreen()}>
                        <Columns>
                            <Column className="is-full">
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    defaultInputValue={experimentSelected?.key}
                                    name="color"
                                    options={experimentLabels}
                                    onChange={(selected) => {
                                        if (selected === null) {
                                            setExperimentSelected(null);
                                            return;
                                        }
                                        setExperimentSelected(experimentsMetadata.data?.get(selected.label));
                                    }}
                                    placeholder={"Type your experiment key"}
                                />
                            </Column>
                        </Columns>

                        {!experimentSelected ?
                            <InfoCard title={"You need to select an experiment."}/>
                            :
                            <>
                                <ExperimentMetadata experiment={experimentSelected}/>
                                <br/>
                                <ExperimentStats experimentKey={experimentSelected.key}/>
                            </>
                        }
                    </StateHandler>
                </Container>
            </AuthenticatedTemplate>
        </>
    );
}
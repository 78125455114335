import LoadingSpinner from "./LoadingSpinner";
import {ErrorCard} from "./ErrorCard";

const StateHandler = (props) => {
    const {showLoader = false, showError = false} = props;
    if (showLoader) {
        return props.loadingScreen ? props.loadingScreen : <LoadingSpinner/>;
    }
    if (showError) {
        return <ErrorCard title={"We had issues fetching your data"}/>
    }
    return props.children;
}
export default StateHandler;
import Icon from "./Icon";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

/**
 * An info card.
 *
 * -- Props --
 * | Name       | Type      | Required  | Default Value | Description
 * |------------|-----------|-----------|---------------|---------------
 * | title      | string    | false     | ""            | The modal title
 */
export const InfoCard = (props) => {
  return (
      <div className="card has-background-light">
        <div className="card-content">
          <div className="content">
            <Icon icon={faInfoCircle}/>
            {props.title}
          </div>
        </div>
      </div>
  );
}

export default InfoCard;

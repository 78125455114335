import './css/defaults.css'
import {useEffect, useState} from "react";
import Icon from "./Icon";
import {faArrowDown, faArrowLeft, faArrowRight, faArrowUp} from "@fortawesome/free-solid-svg-icons";

/**
 * A paged table.
 *
 * -- Props --
 * | Name       | Type    | Required  | Default Value | Description
 * |------------|---------|-----------|---------------|---------------
 * | id         | string  | false     | null          | An id to apply to this component.
 * | className  | string  | false     | ""            | Additional classes to apply to this component.
 * | headers    | array   | false     | []            | An array of table headers.
 * | rows       | array   | false     | []            | An array of rows for the table.
 * | footers    | array   | false     | []            | An array of table footers.
 * | pageSize   | int     | false     | []            | The max number of rows per page.
 *
 * -- Headers --
 * Each element of headers is an object with fields:
 * <pre>
 * {
 *     id: "", -- A unique id for the header
 *     className: "", -- A class to apply to the header
 *     value: <> -- The value to render as the table header
 * }
 * </pre>
 *
 * -- Rows --
 * Each element of rows is an array with form:
 * <pre>
 * {
 *     id: "", -- A unique id for the row
 *     className: "", -- A class to apply to the row
 *     values: [...] -- The row data
 * }
 * </pre>
 *
 * Each element of values is an object with form:
 * <pre>
 * {
 *     id: "", -- A unique id for the row data
 *     className: "", -- A class to apply to the row data
 *     value: <> -- The value to render as the row data
 * }
 * </pre>
 *
 * -- Footers --
 * Each element of footers is an object with form:
 * <pre>
 * {
 *     id: "", -- A unique id for the footer
 *     className: "", -- A class to apply to the footer
 *     value: <> -- The value to render as the table footer
 * }
 * </pre>
 */
export const Table = (props) => {

    const [currentPageNumber, setCurrentPageNumber] = useState(0)
    const [pages, setPages] = useState(new Map())
    const [sort, setSort] = useState(undefined)

    function changeSortOrder(idx) {
        const newSortOrder = idx !== sort.idx ? "down" :
            sort.order === "down" ? "up" : "down"
        setSort({"idx": idx, "order": newSortOrder})
    }

    function getSortIcon() {
        return sort?.order === "down" ? faArrowDown : faArrowUp;
    }

    let tableHeaders = null;
    if (props.headers) {
        tableHeaders = props.headers.values.map((header, idx) => {
            if (!sort) {
                setSort({"idx": idx, "order": "down"})
            }
            return (
                <th className={props.headers.className + " is-clickable is-underlined"} key={header.id}

                    onClick={() => changeSortOrder(idx)}>
                    {header.value}
                    {sort?.idx === idx ?
                        <Icon className={"is-clickable"} icon={getSortIcon()}
                              onClick={() => changeSortOrder(idx)}/> : <></>
                    }
                </th>
            );
        });
    }

    function sortRowsComparator(a, b) {
        const valueA = a['values'][sort.idx]['value']
        const valueB = b['values'][sort.idx]['value']
        if (typeof valueA === "number") {
            const operator = sort.order === "down" ? -1 : 1
            return (-operator * valueB) + (operator * valueA)
        }
        if (typeof valueA === "string") {
            if(sort.order === "up") {
                return valueB.localeCompare(valueA)
            }
            if(sort.order === "down") {
                return valueA.localeCompare(valueB)
            }
        }
    }

    function rowsToPages() {
        if (props.rows && props.rows.length > 0) {
            const size = props.pageSize ? props.pageSize : props.rows.length
            const tempPages = new Map()
            const sortedRows = props.rows.sort((a, b) => sortRowsComparator(a, b))
            for (let i = 0; i < sortedRows.length; i = i + size) {
                tempPages.set(tempPages.size + 1, sortedRows.slice(i, Math.min(sortedRows.length, i + size))
                    .map((row) => {
                        const tableData = row.values.map((rowData) => {
                            return (
                                <td className={rowData.className} key={rowData.id}>{rowData.value}</td>
                            );
                        });

                        return (
                            <tr className={row.className} key={row.id}>{tableData}</tr>
                        );
                    }))
            }
            setPages(tempPages)
        } else {
            setCurrentPageNumber(0)
            setPages(new Map())
        }
    }

    useEffect(() => {
        rowsToPages()
    }, [props])

    useEffect(() => {
        rowsToPages()
    }, [sort])

    useEffect(() => {
        if (pages.size === 0) {
            return
        }
        setCurrentPageNumber(1)
    }, [pages])

    function leftArrowClassname() {
        let className = "mr-4 "
        if (hasPreviousPage()) {
            className += "is-clickable"
        }
        return className
    }

    function rightArrowClassname() {
        let className = "ml-4 "
        if (hasNextPage()) {
            className += "is-clickable"
        }
        return className
    }

    function hasNextPage() {
        return currentPageNumber < pages.size
    }

    function hasPreviousPage() {
        return currentPageNumber > 1
    }

    function previousPage() {
        if (hasPreviousPage()) {
            setCurrentPageNumber(currentPageNumber - 1)
        }
    }

    function nextPage() {
        if (hasNextPage()) {
            setCurrentPageNumber(currentPageNumber + 1)
        }
    }

    return (
        <table id={props.id || null}
               className={`table is-fullwidth is-striped is-hoverable${props.className ? " " + props.className : ""}`}>
            <thead>
            <tr>{tableHeaders}</tr>
            </thead>
            <tbody>{pages.get(currentPageNumber)}</tbody>
            {currentPageNumber > 0 ? <tfoot>
            <tr>
                <th>
                    <Icon icon={faArrowLeft} className={leftArrowClassname()} onClick={previousPage}/>
                    <>{currentPageNumber} of {pages.size}</>
                    <Icon icon={faArrowRight} className={rightArrowClassname()} onClick={nextPage}/>
                </th>
            </tr>
            </tfoot> : <div className="m-4 has-text-weight-semibold">No data available</div>}

        </table>
    );
}

export default Table;

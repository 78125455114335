const experimentation_api_prefix = 'experimentation-api/'
const thor_api_prefix = 'thor-api/'

const experimentation_api_urls = {
    baseUrl: 'insights/v1/aggregations/experiments/',
    getBucketAggregations: getBucketAggregationsUrl,
    getAttributesAggregations: getAttributesAggregationsUrl,
    getAttributeValuesAggregations: getAttributeValuesAggregationsUrl,
    getIneligibilityReasons: getIneligibilityReasonsUrl
}

function getBucketAggregationsUrl(experimentKey, environment, startDate, endDate) {
    let url = experimentation_api_prefix + experimentation_api_urls.baseUrl + experimentKey + "/buckets?"
    if (environment) {
        url += "environment=" + environment + "&"
    }
    if (startDate) {
        url += "startAggregationDateHour=" + startDate + "&"
    }
    if (endDate) {
        url += "endAggregationDateHour=" + endDate + "&"
    }
    return url
}

function getAttributesAggregationsUrl(experimentKey, environment, startDate, endDate) {
    let url = experimentation_api_prefix + experimentation_api_urls.baseUrl + experimentKey + "/attributes?"
    if (environment) {
        url += "environment=" + environment + "&"
    }
    if (startDate) {
        url += "startAggregationDateHour=" + startDate + "&"
    }
    if (endDate) {
        url += "endAggregationDateHour=" + endDate + "&"
    }
    return url
}

function getAttributeValuesAggregationsUrl(experimentKey, attribute, environment, startDate, endDate, bucketKey = undefined, reasonIneligible = undefined) {
    let url = experimentation_api_prefix + experimentation_api_urls.baseUrl + experimentKey + "/attributes/" + attribute + "/values?"
    if (environment) {
        url += "environment=" + environment + "&"
    }
    if (startDate) {
        url += "startAggregationDateHour=" + startDate + "&"
    }
    if (endDate) {
        url += "endAggregationDateHour=" + endDate + "&"
    }
    if (bucketKey) {
        url += "bucketKey=" + bucketKey + "&"
    }
    if (reasonIneligible) {
        url += "reasonIneligible=" + reasonIneligible + "&"
    }
    return url
}

function getIneligibilityReasonsUrl(experimentKey, environment, startDate, endDate) {
    let url = experimentation_api_prefix + experimentation_api_urls.baseUrl + experimentKey + "/ineligibilityReasons?"
    if (environment) {
        url += "environment=" + environment + "&"
    }
    if (startDate) {
        url += "startAggregationDateHour=" + startDate + "&"
    }
    if (endDate) {
        url += "endAggregationDateHour=" + endDate + "&"
    }
    return url
}

const thor_api_urls = {
    listAllExperiments: thor_api_prefix + 'api/v1/experiments?size=10000000',
}

export {experimentation_api_urls, thor_api_urls}
import Container from "../../common/layout/Container";
import {faAddressCard, faCogs, faMagnifyingGlassChart, faToggleOn, faWrench} from "@fortawesome/free-solid-svg-icons";
import "../css/insights.css"
import IconCard from "../../common/elements/IconCard";
import {useState} from "react";
import Columns from "../../common/layout/Columns";
import Column from "../../common/layout/Column";

export const ExperimentMetadata = (props) => {

    const [isCollapsed, setIsCollapsed] = useState(true);

    function changeIsCollapsedFlag() {
        setIsCollapsed(!isCollapsed)
    }

    function buildColumn(key, value, className = "is-half") {
        return <Column className={className + " fa-border ml--1 mt--1"}>
          <span
              className="has-text-weight-bold">{key}</span>
            <span
                className="fa-pull-right">{value}</span>
        </Column>
    }

    function resolveUserReshuffleType(value) {
        return value ? "Daily" : "None"
    }

    function resolveMemberTest(value) {
        return value ? "Member-based Test" : "Unique User Test"
    }

    function resolveBoolean(value) {
        return value ? capitalizeFirstLetter(value) : "False"
    }

    function resolveTimestampColumn(key, value) {
        return value ? buildColumn(key, value) : <></>
    }

    function capitalizeFirstLetter(value) {
        const valueAsString = value.toString()
        return valueAsString.charAt(0).toUpperCase() + valueAsString.slice(1);
    }

    return (
        <Container>
            <div className="card has-background-light pb-2">
                <div className="card-content">
                    <IconCard icon={faCogs} title={"Metadata"}
                              body={"Read-only view of your experiment's configuration"}
                              isCollapsed={isCollapsed}
                              collapse={changeIsCollapsedFlag}
                              collapsedSubtitle={"Expand to see your experiment's configuration"}
                    />
                    {
                        isCollapsed ? <></> :
                            <>
                                <div>
                                    <IconCard icon={faAddressCard} title={"General"}
                                    />
                                    <Columns id={props.id} className={"is-multiline ml-1 mt-1 is-collapsible"}>
                                        {buildColumn("Key", props.experiment['key'])}
                                        {buildColumn("Name", props.experiment['name'])}
                                        {buildColumn("Description", props.experiment['description'])}
                                        {buildColumn("Type", props.experiment['type'].toUpperCase())}
                                        {buildColumn("Owner", props.experiment['owner'])}
                                        {buildColumn("Created Date", props.experiment['createdAt'])}
                                        {buildColumn("Is Archived?", resolveBoolean(props.experiment['archived']))}
                                    </Columns>
                                    <br/>
                                    <IconCard icon={faToggleOn} title={"Status"}
                                    />
                                    <Columns id={props.id} className={"is-multiline ml-1 mt-1 is-collapsible"}>
                                        {buildColumn("Development Status", capitalizeFirstLetter(props.experiment['devStatus']))}
                                        {buildColumn("Production Status", capitalizeFirstLetter(props.experiment['prodStatus']))}
                                    </Columns>
                                    <br/>
                                    <IconCard icon={faWrench} title={"Configuration"}
                                    />
                                    <Columns id={props.id} className={"is-multiline ml-1 mt-1 is-collapsible"}>
                                        {buildColumn("Traffic Allocation", props.experiment['trafficAllocation'] + "%")}
                                        {buildColumn("Salt", resolveUserReshuffleType(props.experiment['userReshuffleType']))}
                                        {buildColumn("Forced Bucket Skip Audience", resolveBoolean(props.experiment['forcedBucketsSkipAudience']))}
                                        {buildColumn("Bucketing Type", resolveMemberTest(props.experiment['memberTest']))}
                                    </Columns>
                                    <br/>
                                    <IconCard icon={faMagnifyingGlassChart} title={"Analysis"}
                                    />
                                    <Columns id={props.id} className={"is-multiline ml-1 mt-1 is-collapsible"}>
                                        {buildColumn("Bucket Comparison Mode", capitalizeFirstLetter(props.experiment['bucketComparisonsMode']))}
                                        {buildColumn("Salt", resolveUserReshuffleType(props.experiment['userReshuffleType']))}
                                        {buildColumn("Is Analysis Required?", resolveBoolean(props.experiment['analysisRequired']))}
                                        {buildColumn("Is Long Running?", resolveBoolean(props.experiment['longRunning']))}
                                        {resolveTimestampColumn("Start Analysis Date", props.experiment['analysisStartDate'])}
                                        {resolveTimestampColumn("End Analysis Date", props.experiment['analysisEndDate'])}
                                        {resolveTimestampColumn("Last Reset Timestamp", props.experiment['lastResetTimestamp'])}
                                    </Columns>
                                </div>
                            </>
                    }
                </div>
            </div>
        </Container>
    );
}
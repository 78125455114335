import {Doughnut} from "react-chartjs-2";
import {ArcElement, Chart, Colors, Legend, Title, Tooltip} from 'chart.js'
import {useEffect, useState} from "react";

Chart.register([ArcElement, Title, Tooltip, Legend, Colors]);
/**
 * An interactive Doughnut Chart
 *
 * -- Props --
 * | Name         | Type    | Required  | Default Value | Description
 * |--------------|---------|-----------|---------------|---------------
 * | data         | string  | false     | null          | Data for DoughnutChart
 * | title        | string  | false     | ""          | Title of the Doughnut Chart
 * | className    | string  | false     | ""            | Additional classes to apply to this component.
 */
export const DoughnutChart = (props) => {
    const [labels, setLabels] = useState([])
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)

    const titlePlugin = {
        beforeDraw: function (chart) {
            if (chart.config.options.elements.center) {
                // Get ctx from string
                var ctx = chart.ctx;

                // Get options from the center object in options
                var centerConfig = chart.config.options.elements.center;
                var fontStyle = centerConfig.fontStyle || 'Arial';
                var txt = centerConfig.text;
                var color = centerConfig.color || '#000';
                var maxFontSize = centerConfig.maxFontSize || 75;
                var sidePadding = centerConfig.sidePadding || 20;
                var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
                // Start with a base font of 30px
                ctx.font = "30px " + fontStyle;

                // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                var stringWidth = ctx.measureText(txt).width;
                var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

                // Find out how much the font can grow in width.
                var widthRatio = elementWidth / stringWidth;
                var newFontSize = Math.floor(30 * widthRatio);
                var elementHeight = (chart.innerRadius * 2);

                // Pick a new font size so it will not be larger than the height of label.
                var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                var minFontSize = centerConfig.minFontSize;
                var lineHeight = centerConfig.lineHeight || 25;
                var wrapText = false;

                if (minFontSize === undefined) {
                    minFontSize = 20;
                }

                if (minFontSize && fontSizeToUse < minFontSize) {
                    fontSizeToUse = minFontSize;
                    wrapText = true;
                }

                // Set font settings to draw it correctly.
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                ctx.font = fontSizeToUse + "px " + fontStyle;
                ctx.fillStyle = color;

                if (!wrapText) {
                    ctx.fillText(txt, centerX, centerY);
                    return;
                }

                var words = txt.split(' ');
                var line = '';
                var lines = [];

                // Break words up into multiple lines if necessary
                for (var n = 0; n < words.length; n++) {
                    var testLine = line + words[n] + ' ';
                    var metrics = ctx.measureText(testLine);
                    var testWidth = metrics.width;
                    if (testWidth > elementWidth && n > 0) {
                        lines.push(line);
                        line = words[n] + ' ';
                    } else {
                        line = testLine;
                    }
                }

                // Move the center up depending on line height and number of lines
                centerY -= (lines.length / 2) * lineHeight;

                for (var n = 0; n < lines.length; n++) {
                    ctx.fillText(lines[n], centerX, centerY);
                    centerY += lineHeight;
                }
                //Draw text in center
                ctx.fillText(line, centerX, centerY);
            }
        }
    }

    function formatNumber(number) {
        let formatter = Intl.NumberFormat('en-US', {
            notation: 'compact',
            compactDisplay: 'short',
            minimumSignificantDigits: 2,
            maximumSignificantDigits: 4
        })
        return formatter.format(number)
    }

    function calculateTotal(excludeIdentifiers = []) {

        if (props.data) {
            let newTotal = 0
            for (let dataPoint of props.data) {
                if (!excludeIdentifiers.includes(dataPoint["aggregationIdentifier"])) {
                    newTotal += dataPoint["counter"]
                }
            }
            setTotal(newTotal)
        } else {
            setTotal(0)
        }
    }

    useEffect(() => {
        let aggregationData = props.data
        let newLabels = []
        let newData = []
        if (aggregationData) {
            for (let dataPoint of aggregationData) {
                newLabels.push(dataPoint["aggregationIdentifier"])
                newData.push(dataPoint["counter"])
            }
            setLabels(newLabels)
            setData(newData)
            calculateTotal()
        }

    }, [props])

    function calculatePercentage(label) {
        return 100 * data[labels.findIndex(value => value === label)] / total;
    }

    return (
        <div className="centered-column">
            <Doughnut data={{
                labels: labels,
                datasets: [{
                    data: data,
                    borderWidth: 1
                }]
            }} options={{
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom',
                        onClick: function (e, legendItem, legend) {
                            legend.chart.toggleDataVisibility(legendItem.index);
                            legend.chart.update();
                            calculateTotal(legend.legendItems.filter(legendItem => legendItem.hidden).map(legendItem => legendItem.text))
                        }
                    },
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            footer: function (context) {
                                return "Percentage: " + formatNumber(calculatePercentage(context[0].label)) + "%";
                            }
                        }
                    },
                    colors: {
                        forceOverride: true
                    }
                },
                elements: {
                    center: {
                        text: formatNumber(total),
                        color: '#03D1B2',
                        fontStyle: 'Arial', // Default is Arial
                        sidePadding: 20, // Default is 20 (as a percentage)
                        minFontSize: 25, // Default is 20 (in px), set to false and text will not wrap.
                        lineHeight: 25 // Default is 25 (in px), used for when text wraps
                    }
                }
            }} plugins={[titlePlugin]}
            >
            </Doughnut>
        </div>
    );
}

export default DoughnutChart;

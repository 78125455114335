import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';

export const Login = () => {
  const { instance } = useMsal();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
  return (
    <>
      <AuthenticatedTemplate>
        <div className="collapse navbar-collapse justify-content-end">
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        { handleLoginRedirect }
      </UnauthenticatedTemplate>
    </>
  );
};
import { AuthenticatedTemplate } from '@azure/msal-react';

import { Login } from '../../Login.jsx'
import { useEffect } from "react";
import HomeBody from "./HomeBody";

export const HomeComponent = () => {
  useEffect(() => {
    document.title = 'Experimentation Dashboard';
  }, []);

  return (
    <div>
      <Login/>
      <AuthenticatedTemplate>
        <HomeBody />
      </AuthenticatedTemplate>
    </div>
  );
}


import Icon from "./Icon";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";

/**
 * An icon.
 *
 * -- Props --
 * | Name         | Type        | Required    | Default Value | Description
 * |--------------|-------------|-------------|---------------|---------------
 * | id           | string      | false       | null          | An id to apply to this component.
 * | className    | string      | false       | ""            | Additional classes to apply to this component.
 * | icon         | component   | true        | N/A           | The icon component to render.
 * | onClick      | function    | false       | null          | An onClick function.
 * | onMouseLeave | function    | false       | null          | An onMouseLeave function.
 * | tooltip      | string      | false       | null          | A tooltip to display.
 * | collapse     | boolean     | false       | false         | Flag to indicate if the card should be collapsible.
 * | isCollapsed  | boolean     | false       | false         | Flag to indicate if the card is collapsed.
 */
export const IconCard = (props) => {
    function getCollapsibleIcon() {
        if (props.isCollapsed) {
            return faAngleDown
        } else {
            return faAngleUp
        }
    }

    function getSubtitle() {
        if (props.isCollapsed) {
            return props.collapsedSubtitle
        } else {
            return props.body
        }
    }

    return (

        <div className="is-align-content-center pb-4">
            <Icon icon={props.icon}/>
            <span className="is-size-4 pl-2">{props.title}</span>
            {props.collapse !== undefined ?
                <Icon icon={getCollapsibleIcon()} className="ml-4 is-clickable" onClick={props.collapse}/> :
                <></>}
            {props.body !== undefined ?
                <>
                    <br/>
                    <span> {getSubtitle()}</span>
                </> :
                <></>
            }

        </div>
    );
}

export default IconCard;

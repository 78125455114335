import Columns from "../common/layout/Columns";
import Column from "../common/layout/Column";
import Container from "../common/layout/Container";
import Hero from "../common/layout/Hero";
import HeroBody from "../common/layout/HeroBody";
import HomeCard from "./HomeCard";

import {faBinoculars, faGavel} from "@fortawesome/free-solid-svg-icons";
import "./css/home.css";

const HomeBody = () => {
    return (
        <Container>
            <Hero>
                <HeroBody>
                    <p className="title is-1">Experimentation Dashboard</p>
                    <p className="subtitle">Visualize your experiment's data.</p>
                </HeroBody>
            </Hero>
            <Columns>
                <Column className="is-half">
                    <HomeCard title="Real-time Insights"
                              description="See bucketing decisions in real-time."
                              href="/real-time-insights"
                              icon={faBinoculars}
                              buttonText="Real-time Insights"
                              buttonTarget="_self"
                    />
                </Column>
                <Column className="is-half">
                    <HomeCard title="Thor Hammer"
                              description="Override yourself into buckets."
                              href="/thor-hammer"
                              icon={faGavel}
                              buttonText="Coming soon"
                              buttonTarget="_self"
                              disabled={true}
                    />
                </Column>

            </Columns>
        </Container>
    );
};

export default HomeBody;

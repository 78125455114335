export function buildLoadingState(defaultDataValue = []) {
    return {
        loading: true,
        error: null,
        data: defaultDataValue
    }
}

export function buildDataState(data) {
    return {
        loading: false,
        error: null,
        data: data
    }
}

export function buildErrorState(error, defaultDataValue = []) {
    return {
        loading: false,
        error: error,
        data: defaultDataValue
    }
}
